<template>
  <div class="popover-container">
    <el-popover
      v-model="popoverVisible"
      :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
      trigger="manual"
      width="461"
    >
      <div slot="reference" class="main-button-preview-container">
        <el-button :class="['popover-button', { overlapping: showMultipleIcons }]" @click="togglePopover">
          <div v-if="showCommentIcon || showGuidanceIcon" class="main-icons-container">
            <div v-if="showNotificationDot" class="red-dot"></div>
            <NoteIcon v-if="showCommentIcon" :size="20" class="main-icon first-icon"></NoteIcon>
            <CompassIcon v-if="showGuidanceIcon" :size="20" class="main-icon second-icon"></CompassIcon>
          </div>
          <NewNoteIcon v-else :size="20" class="new-note-icon"></NewNoteIcon>
        </el-button>

        <div v-if="comment" class="comment-preview-container">
          <span class="comment-preview">{{ comment }}</span>
          <el-button class="expand-button" @click="togglePopover">
            <ChevronDownIcon :size="20" class="expand-icon"></ChevronDownIcon>
          </el-button>
        </div>
      </div>

      <div class="popover-header-container">
        <div class="header-text">
          {{ $t('tasks.singularTaskLayout.commentAndGuidances') }}
        </div>
        <div class="header-buttons">
          <el-button class="popover-button" @click="addNewGuidance">
            <PlusIcon :size="20" class="header-icon"></PlusIcon>
          </el-button>
          <el-button class="popover-button" @click="closePopover">
            <CloseIcon :size="20" class="header-icon"></CloseIcon>
          </el-button>
        </div>
      </div>

      <div class="popover-components-container">
        <CommentSection
          class="comment-section-container"
          :comment="comment"
          @comment-changed="updateComment"
        ></CommentSection>
        <GuidanceSection :guidances="guidances"></GuidanceSection>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { ChevronDownIcon, CloseIcon, CompassIcon, NoteIcon, NewNoteIcon, PlusIcon } from '@/assets/icons';
import CommentSection from './CommentSection.vue';
import GuidanceSection from './GuidanceSection.vue';
import { isEmpty } from 'ramda';

export default {
  components: {
    CommentSection,
    GuidanceSection,
    ChevronDownIcon,
    CloseIcon,
    CompassIcon,
    NoteIcon,
    NewNoteIcon,
    PlusIcon,
  },
  props: {
    taskId: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
    guidances: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const popoverVisible = ref(false);
    const updatedDataSeenAtLeastOnce = ref(false);

    const showCommentIcon = computed(() => !!props.comment);
    const showGuidanceIcon = computed(() => !isEmpty(props.guidances));
    const showMultipleIcons = computed(() => showCommentIcon.value && showGuidanceIcon.value);
    const showNotificationDot = computed(() => {
      return (showCommentIcon.value || showGuidanceIcon.value) && !updatedDataSeenAtLeastOnce.value;
    });

    const togglePopover = () => {
      updatedDataSeenAtLeastOnce.value = true;
      popoverVisible.value = !popoverVisible.value;
    };

    const closePopover = () => {
      popoverVisible.value = false;
    };

    const addNewGuidance = () => {
      emit('new-guidance-clicked');
      closePopover();
    };

    const updateComment = (value) => {
      emit('comment-changed', value);
    };

    watch(
      () => props.taskId,
      () => {
        updatedDataSeenAtLeastOnce.value = popoverVisible.value;
      }
    );

    watch(
      () => props.guidances,
      (value) => {
        if (!isEmpty(value)) {
          updatedDataSeenAtLeastOnce.value = popoverVisible.value;
        }
      }
    );

    return {
      popoverVisible,
      updatedDataSeenAtLeastOnce,
      showCommentIcon,
      showGuidanceIcon,
      showMultipleIcons,
      showNotificationDot,
      togglePopover,
      closePopover,
      addNewGuidance,
      updateComment,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.popover-container {
  display: flex;
  align-items: center;
}

.popover-button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  position: relative;
}

.popover-button:hover {
  background: none;
}

.main-button-preview-container {
  display: flex;
  align-items: center;
  margin: 0 8px 0 8px;
}

.main-icons-container {
  position: relative;
}

.main-icons-container .first-icon {
  top: 0;
  left: 0;
  z-index: 1;
}

.main-icons-container .second-icon {
  top: 0;
  left: 8px;
  z-index: 2;
}

.red-dot {
  width: 12px;
  height: 12px;
  position: absolute;
  top: -3px;
  left: -3px;
  background-color: #e52044;
  border-radius: 50%;
  border: 2.5px solid #fff;
  z-index: 3;
}

.main-icon {
  color: $typography-primary;
}

.new-note-icon {
  color: $typography-secondary;
}

.new-note-icon:hover {
  color: $typography-primary;
}

.comment-preview-container {
  max-width: 367px;
  display: inline-flex;
  align-items: center;
  margin: 0 8px;
}

.comment-preview {
  font-size: 12px;
  color: #606266;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-width: 100%;
}

.expand-button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  position: relative;
}

.expand-icon {
  color: $typography-secondary;
}

.popover-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebeef5;
}

.header-text {
  font-weight: 600;
}

.header-buttons {
  display: flex;
}

.header-icon {
  cursor: pointer;
  color: $typography-secondary;
}

.header-icon:hover {
  color: $typography-primary;
}

.popover-components-container {
  padding: 12px 0 12px 0;
}

.comment-section-container {
  margin-bottom: 24px;
}
</style>
