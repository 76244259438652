<template>
  <div class="page-layout">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h1>{{ title }}</h1>
        <TaskTag class="mx-3" :task="task" :display-mode="true" />
      </div>
      <slot name="actions"></slot>
    </div>
    <slot name="details"></slot>
    <slot name="content"></slot>
    <div class="w-100 d-flex justify-content-end">
      <div class="actions-wrapper d-flex">
        <div class="actions-wrapper__move-next" @click="handleUpdateClick">
          {{ $t('tasks.taskLayout.updateAndMoveToNextTask') }}
        </div>
        <div class="actions-wrapper__divider" />
        <el-dropdown
          class="actions-wrapper__close-task"
          trigger="click"
          placement="top-start"
          @command="() => handleMarkCompleteMoveNext()"
        >
          <div>
            <ChevronIcon />
          </div>
          <el-dropdown-menu>
            <el-dropdown-item :disabled="!taskCloseable">
              <CheckCircleIcon /> {{ getCloseTaskText() }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <TaskSnoozeModal
      v-if="openSnoozeModal"
      :task="task"
      @update-task="handleTaskUpdate"
      @close="openSnoozeModal = false"
    >
      <template #subtile>
        <slot name="task-snooze-modal-subtitle" />
      </template>
    </TaskSnoozeModal>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { isNil } from 'ramda';

import { ChevronIcon, CheckCircleIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import { TaskTag } from '@/modules/tasks/taskTag';

import { TaskSnoozeModal } from '../../components';
import { TASKS_QUERY } from '../../compositions/graphql';
import { usePatchTask, useActivateTask, useCompleteTask } from '../../compositions/useTasks';

const DOMAIN = {
  RESTAURANT_SUPPLIER_RELATIONS: 'restaurantSupplierRelations',
  RECONCILIATION: 'reconciliation',
  BILLING: 'billing',
};

export default {
  components: { TaskTag, TaskSnoozeModal, ChevronIcon, CheckCircleIcon },
  props: {
    title: { type: String, required: true },
    task: { type: Object, required: true },
    actions: { type: Object, default: () => {} },
    taskCloseable: { type: Boolean, default: true },
    validated: { type: Boolean, default: null },
    customCloseTaskText: { type: String, default: null },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const { completeTask, onDone: onTaskComplete } = useCompleteTask();

    onTaskComplete(() => {
      root.$message.success(root.$t('commons.messages.action.success'));
    });

    const { patchTask } = usePatchTask();
    const { activeTask } = useActivateTask();

    return {
      patchTask,
      activeTask,
      completeTask,
      openSnoozeModal: ref(false),
      currentTenant,
    };
  },
  methods: {
    async validate() {
      const waitForParentPromise = this.waitForParent();
      this.$emit('validate');
      await waitForParentPromise;
    },
    async waitForParent() {
      return new Promise((resolve) => this.$parent.$once('parent-event-handled', () => resolve()));
    },
    async handleTaskUpdate(updateData) {
      this.$emit('layout-loading', true);
      this.patchTask({
        taskId: updateData.task.id,
        patchParams: {
          data: {
            ...updateData.task.data,
            comment: updateData.comment,
            ...(this.actions && { subTasks: this.actions }),
          },
        },
      });
      if (!isNil(updateData.activeAt)) {
        this.activeTask({
          taskId: updateData.task.id,
          activeParams: {
            activeAt: updateData.activeAt,
          },
        });
      }
      await this.continueToNextTask();
      this.$emit('layout-loading', false);
    },
    async handleMarkCompleteMoveNext() {
      if (this.task.type === 'uncertainBilling') {
        await this.handleUncertainBillingComplete();
      } else {
        await this.handleRegularTaskComplete();
      }
    },
    async handleUncertainBillingComplete() {
      this.$emit('handle-mark-complete', async () => {
        this.$emit('layout-loading', true);
        await this.continueToNextTask();
        this.$emit('layout-loading', false);
      });
    },
    async handleRegularTaskComplete() {
      if (!isNil(this.validated)) {
        await this.validate();
        if (!this.validated) return;
      }
      this.$emit('layout-loading', true);
      this.completeTask({ taskId: this.task.id });
      await this.continueToNextTask();
      this.$emit('layout-loading', false);
    },
    async continueToNextTask() {
      this.$emit('before-next-task');
      const nextTask = await this.getNextTask();
      if (isNil(nextTask)) {
        this.$router.push({ name: this.getTab(this.task.domain) });
      } else {
        this.$router.push({ name: this.getRouteName(this.task.domain), params: { taskId: nextTask.id } });
      }
    },
    async getNextTask() {
      const { data } = await this.$apollo.query({
        query: TASKS_QUERY,
        variables: {
          businessId: this.currentTenant.id,
          completed: false,
          domains: this.task.domain,
          type: this.task.type,
          limit: 2,
          offset: 0,
        },
      });
      return data.tasksNew2.nodes.filter(({ id }) => id !== this.task.id)[0];
    },
    async handleUpdateClick() {
      if (!isNil(this.validated)) {
        await this.validate();
        if (!this.validated) return;
      }
      this.openSnoozeModal = true;
    },
    getTab(domain) {
      switch (domain) {
        case DOMAIN.RESTAURANT_SUPPLIER_RELATIONS:
          return 'supplier-create-tasks';
        case DOMAIN.RECONCILIATION:
          return 'reconciliation-tasks';
        case DOMAIN.BILLING:
          return 'uncertain-billing-tasks';
        default:
          return '';
      }
    },
    getRouteName(domain) {
      switch (domain) {
        case DOMAIN.RESTAURANT_SUPPLIER_RELATIONS:
          return 'supplierTasks';
        case DOMAIN.RECONCILIATION:
          return 'reconciliationTask';
        case DOMAIN.BILLING:
          return 'uncertainBillingTask';
        default:
          return '';
      }
    },
    getCloseTaskText() {
      return this.customCloseTaskText ?? this.$t('tasks.taskLayout.markAsCompleteAndMoveToNextTask');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.page-layout {
  min-height: 100%;
  margin: 56px 24px;
}

* :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(p):not(span):not(small) {
  font-size: 0.875rem;
  font-weight: 400;
}

.actions-wrapper {
  cursor: pointer;
  margin-top: 56px;
  background: #306feb;
  height: 40px;
  border-radius: 4px;

  &__move-next {
    width: 194px;
  }
  &__close-task {
    width: 40px;
  }
  &__divider {
    background: #0d50d4;
    width: 2px;
  }
  &__move-next,
  &__close-task {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #6596f7;
      border-radius: 4px;
    }
  }
}
</style>
