<template>
  <div class="comment-section">
    <div :class="$direction === 'rtl' ? 'icon-rtl' : 'icon-ltr'">
      <NoteIcon :size="20" />
    </div>
    <div class="content">
      <div class="comment-title">{{ $t('tasks.singularTaskLayout.comments') }}</div>

      <!-- View Mode -->
      <div v-if="!isEditing">
        <!-- No existing comment -->
        <a v-if="!comment" class="add-comment" @click="enterEditMode">{{
          $t('tasks.singularTaskLayout.addCommentPlaceholder')
        }}</a>

        <!-- Existing comment -->
        <span
          v-else
          class="comment-text-display"
          @mouseover="showPencil = true"
          @mouseleave="showPencil = false"
          @click="enterEditMode"
        >
          <span class="text-wrapper">{{ comment }}</span>
          <EditSolidIcon v-show="showPencil" width="11" height="11" class="pencil-icon mx-2"></EditSolidIcon>
        </span>
      </div>

      <!-- Edit Mode -->
      <div v-else>
        <el-input
          v-model="editableComment"
          type="textarea"
          class="comment-text-edit"
          :placeholder="$t('tasks.singularTaskLayout.commentPlaceholder')"
          :rows="3"
        ></el-input>
        <div class="comment-actions el-message-box__btns">
          <Button type="secondary" @click="cancelEdit">{{
            $t('tasks.singularTaskLayout.cancelCommentChanged')
          }}</Button>
          <Button type="primary" @click="saveComment">{{ $t('tasks.singularTaskLayout.saveCommentChanged') }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { EditSolidIcon, NoteIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

export default {
  components: {
    Button,
    EditSolidIcon,
    NoteIcon,
  },
  props: {
    comment: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false);
    const editableComment = ref(props.comment);
    const showPencil = ref(false);

    const enterEditMode = () => {
      isEditing.value = true;
    };

    const saveComment = () => {
      emit('comment-changed', editableComment.value);
      isEditing.value = false;
    };

    const cancelEdit = () => {
      editableComment.value = props.comment;
      isEditing.value = false;
    };

    watch(
      () => props.comment,
      (newVal) => {
        editableComment.value = newVal;
      }
    );

    return {
      isEditing,
      editableComment,
      showPencil,
      enterEditMode,
      saveComment,
      cancelEdit,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.comment-section {
  display: flex;
  align-items: flex-start;
}

.icon-rtl {
  margin-left: 12px;
}

.icon-ltr {
  margin-right: 12px;
}

.content {
  flex: 1;
}

.comment-title {
  font-weight: 550;
  margin-bottom: 8px;
}

.comment-text-display {
  padding-left: 10px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  word-break: normal;
  text-align: start;
}

.text-wrapper {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.pencil-icon {
  color: $typography-secondary;
}

.add-comment {
  cursor: pointer;
  color: #409eff;
  text-decoration: underline;
}

.comment-text-edit {
  width: 100%;
  word-break: normal;
}

.comment-actions {
  margin-top: 8px;
}
</style>
