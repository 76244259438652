<template>
  <div class="guidances-section">
    <div :class="$direction === 'rtl' ? 'icon-rtl' : 'icon-ltr'">
      <CompassIcon :size="20" />
    </div>
    <div class="content">
      <div class="guidances-title">{{ $t('tasks.singularTaskLayout.guidances') }}</div>
      <div v-for="(guidance, index) in guidances" :key="guidance.id">
        <div class="guidance-text">{{ guidance.guidance }}</div>
        <div class="guidance-meta">
          <span>{{ `${$t('tasks.singularTaskLayout.createdBy')} ${guidance.createdBy}` }}</span>
          <span class="dot-separator">•</span>
          <span class="guidance-date">{{ formatDate(guidance.createdAt) }}</span>
        </div>
        <hr v-if="index < guidances.length - 1" class="guidance-separator" />
      </div>
    </div>
  </div>
</template>

<script>
import { CompassIcon } from '@/assets/icons';
import { DateTime } from 'luxon';

export default {
  components: {
    CompassIcon,
  },
  props: {
    guidances: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? DateTime.fromMillis(ms).toFormat('dd.LL.yyyy, HH:mm') : '';
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.guidances-section {
  display: flex;
  align-items: flex-start;
}

.icon-rtl {
  margin-left: 12px;
}

.icon-ltr {
  margin-right: 12px;
}

.content {
  flex: 1;
}

.guidances-title {
  font-weight: 550;
  margin-bottom: 8px;
}

.guidance-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: $typography-primary;
  margin-bottom: 12px;
}

.guidance-meta {
  font-size: 12px;
  color: $typography-secondary;
  display: flex;
  align-items: center;
}

.dot-separator {
  margin: 0 8px;
}

.guidance-date {
  margin-left: 4px;
}

.guidance-separator {
  border: none;
  border-top: 1px solid #ebeef5;
  margin: 16px 0;
}
</style>
