<template>
  <div class="gx-5 center-container">
    <div>
      <div class="layout-header sticky-top bg-white">
        <Divider v-if="isTaskClosed" :label="$t('tasks.singularTaskLayout.closedTask')" />
        <div class="row">
          <div class="d-flex justify-content-between top-navbar-style">
            <div class="d-flex align-items-center">
              <div class="play-mode-indicator">
                <slot name="play-mode-indicator"></slot>
              </div>
              <h1>{{ title }}</h1>
              <TaskTag class="mx-2" :task="task" :display-mode="true" />
              <div v-if="!isNil(task?.priority)" class="priority-icon-container">
                <component :is="taskPriorityIconMapping[task.priority]"></component>
              </div>
              <el-tooltip
                v-if="task?.reviewRequired"
                :content="$t('tasks.singularTaskLayout.managerReviewRequired')"
                placement="top"
              >
                <FlagFullIcon v-if="task?.reviewRequired" color="#E47E03" />
              </el-tooltip>
              <TaskCommentAndGuidancePopover
                :task-id="task?.id"
                :comment="task?.data?.comment"
                :guidances="guidances"
                @comment-changed="handleTaskReviewChange"
                @new-guidance-clicked="handleOpenGuidanceDrawer"
              />
            </div>

            <SingularTaskNavBarActions
              :task="task"
              :is-loading-task="isLoadingTask"
              :validate-task-before-complete-hook="validateTaskBeforeCompleteHook"
              :requests-target-businesses-ids="requestsTargetBusinessesIds"
              :should-disable-ignore-warnings="shouldDisableIgnoreWarnings"
              :is-actions-disabled="isActionsDisabled"
              :is-task-closed="isTaskClosed"
              :is-skip-action-disabled="isSkipActionDisabled"
              :request-templates="requestTemplates"
              :open-guidance-drawer="openGuidanceDrawer"
              @complete-task="handleCompleteTask"
              @skip-task="openSnoozeModal = true"
              @is-manager-review-required="handleReviewManagerClicked"
              @template-selected="$emit('template-selected', $event)"
              @guidance-created="handleGuidanceCreated"
            >
              <template #pagination>
                <slot name="pagination"></slot>
              </template>

              <template #menu-dropdown-items>
                <slot name="menu-dropdown-items"></slot>
              </template>
            </SingularTaskNavBarActions>
          </div>
        </div>
      </div>
      <div>
        <slot name="content"></slot>
      </div>
      <div class="pb-4">
        <TaskSnoozeModal
          v-if="openSnoozeModal"
          :task="task"
          @snooze-task="handleSkipTask"
          @close="openSnoozeModal = false"
        >
        </TaskSnoozeModal>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, reactive } from 'vue';
import { TaskTag } from '@/modules/tasks/taskTag';
import { FlagFullIcon } from '@/assets/icons';
import TaskSnoozeModal from './TaskSnoozeModal.vue';
import SingularTaskNavBarActions from './SingularTaskNavBarActions.vue';
import { useNotification } from '@/modules/core';
import { taskPriorityIconMapping } from '@/modules/tasks/configuration';

import { useCompleteTaskNew2, usePatchTaskNew2, useActivateTaskNew2 } from '@/modules/tasks';
import { useActivityTrack } from '@/modules/tasks/compositions';
import { ANALYTICS_EVENT_TYPES } from '@/analytics/types';
import { isNil } from 'ramda';
import Divider from './Divider.vue';

import TaskCommentAndGuidancePopover from './TaskCommentAndGuidancePopover.vue';
import { useGuidances } from '../../composition/useGuidances';
import guidanceTypes from '../../../../guidance/guidanceTypes';

export default {
  components: {
    TaskTag,
    TaskSnoozeModal,
    SingularTaskNavBarActions,
    FlagFullIcon,
    Divider,
    TaskCommentAndGuidancePopover,
  },
  props: {
    title: { type: String, required: true },
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
    validateTaskBeforeCompleteHook: {
      type: Function,
      required: true,
      default: async () => {
        return {
          errors: [],
          warnings: [],
        };
      },
    },
    requestsTargetBusinessesIds: {
      type: Array,
      required: false,
      default() {
        return null;
      },
    },
    requestTemplates: { type: Array, required: false, default: () => [] },

    shouldDisableIgnoreWarnings: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    isActionsDisabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    isSkipActionDisabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  emits: ['complete-task', 'skip-task'],
  setup(props, { emit }) {
    const { completeTask } = useCompleteTaskNew2();
    const { error } = useNotification();

    const openSnoozeModal = ref(false);
    const guidances = ref([]);
    const lastUpdatedContent = reactive({});

    const { patchTask } = usePatchTaskNew2();
    const { activeTask } = useActivateTaskNew2();
    useActivityTrack(
      computed(() => props?.task?.id),
      computed(() => `${props?.task?.id}-${Date.now()}`),
      2000
    );

    const isTaskClosed = computed(() => {
      return !isNil(props?.task?.completedAt);
    });

    const taskType = computed(() =>
      props?.task?.type && props?.task?.domain ? `${props.task.type}_${props.task.domain}` : ''
    );

    const { getGuidancesForModel, refetchGuidances } = useGuidances(
      computed(() => ({
        taskType: taskType.value,
      }))
    );

    const refreshGuidances = (content) => {
      const updatedContent = content || lastUpdatedContent.value;
      guidances.value = getGuidancesForModel(updatedContent, guidanceTypes[taskType.value]?.extractFormData);
    };

    const resetGuidances = () => {
      guidances.value = [];
    };

    const onContentChanged = (content) => {
      lastUpdatedContent.value = content;
      refreshGuidances(content);
    };

    const taskIdChanged = async (previousTaskId, currentTaskId) => {
      if (previousTaskId !== currentTaskId) {
        await refetchGuidances();
        resetGuidances();
      }
    };

    watch(() => props?.task?.id, taskIdChanged);

    const patchTaskManagerReview = async (isReviewRequired) => {
      await patchTask({
        taskId: props?.task.id,
        patchParams: {
          reviewRequired: isReviewRequired,
        },
      }).catch((err) => {
        error({ title: err.error, message: err.reason });
      });
    };

    const openGuidanceDrawer = ref(false);

    return {
      handleGuidanceCreated: async () => {
        await refetchGuidances();
        refreshGuidances();
      },

      handleTaskReviewChange: async (comment) => {
        const data = { ...(props?.task.data || {}) };
        if (comment) {
          data.comment = comment;
        } else {
          delete data.comment;
        }

        const patchParams = { data };

        await patchTask({
          taskId: props?.task.id,
          patchParams,
        }).catch((err) => {
          error({ title: err.error, message: err.reason });
        });
      },
      handleReviewManagerClicked: async (isManagerReviewRequired) => {
        if (!isManagerReviewRequired) {
          await patchTaskManagerReview(isManagerReviewRequired);
        } else {
          openSnoozeModal.value = true;
        }
      },

      handleCompleteTask: async () => {
        await completeTask({ taskId: props.task.id }).catch((err) => {
          error({ title: err.error, message: err.reason });
        });

        const taskData = {
          reviewRequired: props.task.reviewRequired,
          activeAt: props.task.activeAt,
          data: props.task.data,
          taskType: taskType.value,
          analyticsEventName: ANALYTICS_EVENT_TYPES.TASKS.COMPLETED,
        };
        emit('complete-task', props.task.id, taskData);
      },

      handleSkipTask: async ({ activeAt, comment, reviewRequired }) => {
        const taskId = props.task.id;
        const originalData = { ...(props.task.data || {}) };

        const data = { ...originalData, comment };
        const patchParams = { data, reviewRequired };

        await patchTask({
          taskId: taskId,
          patchParams,
        }).catch((err) => {
          error({ title: err.error, message: err.reason });
        });

        if (activeAt) {
          await activeTask({
            taskId: taskId,
            activeParams: {
              activeAt,
            },
          }).catch((err) => {
            error({ title: err.error, message: err.reason });
          });
        }

        const taskData = {
          reviewRequired: !props.task.reviewRequired && reviewRequired,
          activeAt,
          data,
          taskType: taskType.value,
          analyticsEventName:
            ANALYTICS_EVENT_TYPES.TASKS[
              !props.task.reviewRequired && reviewRequired ? 'MANAGER_REVIEW_REQUIRED' : 'SNOOZED'
            ],
        };
        openSnoozeModal.value = false;
        emit('skip-task', taskId, taskData);
      },
      handleOpenGuidanceDrawer: () => {
        openGuidanceDrawer.value = !openGuidanceDrawer.value;
      },
      openGuidanceDrawer,
      openSnoozeModal,
      taskType,
      isTaskClosed,
      guidances,
      onContentChanged,
      taskPriorityIconMapping,
      isNil,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.center-container {
  width: 100%;
  margin: 0 auto;
}

.top-navbar-style {
  height: 142px;
  align-items: center;
}

.play-mode-indicator {
  position: absolute;
  top: 30px;
}
.priority-icon-container {
  padding-right: 10px;
  padding-left: 10px;
}
</style>
