import { useNotification } from '@/modules/core';
import { gql } from '@apollo/client';
import { apolloClient } from '@/plugins/apollo-client';
import { useMutation } from '@vue/apollo-composable';

export function useUpdateReconciliation() {
  const { error: errorNotification } = useNotification();

  const { mutate } = useMutation(RECONCILIATION_UPDATE_MUTATION);

  const updateReconciliation = async (variables) => {
    try {
      return mutate(variables);
    } catch (error) {
      console.error(error);
      errorNotification(error);
      throw error;
    }
  };

  return {
    updateReconciliation,
  };
}

const RECONCILIATION_UPDATE_MUTATION = gql`
  mutation update($id: ID!, $patchParams: ReconciliationPatchInput) {
    reconciliationUpdate(id: $id, patchParams: $patchParams) {
      id
      actionsPerformed
      statusReflection {
        reason
        updatedBy {
          firstName
          lastName
        }
        updatedAt
      }
      balanceAlignment {
        required
        validated
      }
    }
  }
`;

export const getReconciliations = (variables) =>
  apolloClient
    .query({
      query: RECONCILIATIONS_QUERY,
      variables,
    })
    .then((result) => result.value?.reconciliations.nodes ?? []);

const RECONCILIATIONS_QUERY = gql`
  query reconciliations($businessId: ID!, $ids: [String]) {
    reconciliations(businessId: $businessId, ids: $ids) {
      nodes {
        businessId
        supplierId
        balanceAlignment {
          required
          validated
          note
        }
      }
    }
  }
`;
