import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { isEmpty, isNil } from 'ramda';

const GUIDANCES_QUERY = gql`
  query guidances($taskType: String!) {
    guidances(taskType: $taskType) {
      nodes {
        createdAt
        taskType
        id
        guidance
        filters
        createdBy {
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`;

const matchGuidanceToModel = ({ filters }, model, fieldExtractor) => {
  return filters.every(({ key, values }) => {
    if (isEmpty(values)) {
      return true;
    }
    const fieldData = fieldExtractor(model, key);
    if (isNil(fieldData)) {
      console.warn({ field: key }, 'useGuidance: Cannot match filter. Could not extract field data');
      return false;
    }
    return values.includes(fieldData);
  });
};

export const useGuidances = (variables) => {
  const queryEnabled = computed(() => !!variables.value.taskType);

  const { result, loading, onError, refetch } = useQuery(GUIDANCES_QUERY, variables, { enabled: queryEnabled });

  onError((error) => {
    console.warn({ error }, 'useGuidance: Failed loading task type guidances');
  });

  const allTaskTypeGuidances = computed(() => result.value?.guidances?.nodes ?? []);

  const getGuidancesForModel = (model, fieldExtractor = () => undefined) => {
    const matchedGuidances = allTaskTypeGuidances.value.filter((guidance) =>
      matchGuidanceToModel(guidance, model, fieldExtractor)
    );
    return matchedGuidances.map(({ id, guidance, createdAt, createdBy: { firstName, lastName } }) => ({
      id,
      guidance,
      createdAt,
      createdBy: `${firstName} ${lastName}`,
    }));
  };

  const refetchGuidances = async () => {
    if (loading.value || !result.value) {
      return;
    }

    await refetch();
  };

  return {
    loading,
    refetchGuidances,
    getGuidancesForModel,
  };
};
